@import '../../shared/shared';

.footer {
  background: #222223;
  padding: toRem(24) toRem(16);
  @include column-centered();
  gap: toRem(32);

  @media screen and (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: $lg) {
    padding: toRem(24) toRem(64);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(24) toRem(100);
  }
}

.logo-container {
  @include display-flex();
  width: toRem(64);
}

.desc {
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 700;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;

  @media screen and (min-width: $md) {
    grid-column: 2;
    grid-row: 1;
  }
}
