@import '../../shared/shared';

.list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: toRem(24);

  @media screen and (min-width: $md) {
    grid-template-columns: 1fr 1fr;
    grid-gap: toRem(24) toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: toRem(32) toRem(20);
  }
}
