@import '../../../../shared/shared';

.section {
  background: #222223;
  padding: toRem(48) toRem(16);

  @media screen and (min-width: $lg) {
    padding: toRem(56) toRem(64);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(64) toRem(100);
  }
}

.purpose {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 700;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) -31.12%,
    #fff 47.48%,
    rgba(255, 255, 255, 0.3) 126.52%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: toRem(32) 0 toRem(48);

  @media screen and (min-width: $lg) {
    font-size: toRem(48);
    padding: toRem(32) 0 toRem(48);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(64);
    padding: toRem(32) 0 toRem(64);
  }
}
