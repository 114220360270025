@font-face {
  font-family: 'Satoshi';
  src: local(Satoshi-Regular),
    url('./assets/fonts/Satoshi-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Regular.woff') format('woff'),
    url('./assets/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: local(Satoshi-Medium),
    url('./assets/fonts/Satoshi-Medium.eot') format('embedded-opentype'),
    url('./assets/fonts/Satoshi-Medium.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Medium.woff') format('woff'),
    url('./assets/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: local(Satoshi-Bold),
    url('./assets/fonts/Satoshi-Bold.eot') format('embedded-opentype'),
    url('./assets/fonts/Satoshi-Bold.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Bold.woff') format('woff'),
    url('./assets/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: local(Satoshi-Black),
    url('./assets/fonts/Satoshi-Black.eot') format('embedded-opentype'),
    url('./assets/fonts/Satoshi-Black.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Black.woff') format('woff'),
    url('./assets/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: block;
  font-style: normal;
}
