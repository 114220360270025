@import '../../shared/shared';

.list {
  @include column-flex();
  gap: toRem(24);

  @media screen and (min-width: $md) {
    flex-direction: row;
    gap: toRem(28);
  }
  @media screen and (min-width: $two-xl) {
    gap: toRem(32);
  }
}
