@import 'shared/shared';
@import 'typography';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  &:focus {
    outline: none;
  }
}

:root {
  --font-family: 'Satoshi', sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  overflow-y: auto;
  @include custom-scrollbar();
}

body {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  color: rgba(47, 52, 90, 0.6);
  background: #fff;
  min-width: toRem(320);
}

.noscroll {
  overflow: hidden;
}

#root {
  width: 100%;
}

main {
  width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  background-color: #0000;
  cursor: pointer;
  border: none;
}

.skeleton {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-40);
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &.index {
    &::after {
      z-index: 2;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--glow-up);
  }
  100% {
    background-color: var(--glow-down);
  }
}
