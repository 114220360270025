.svg {
  position: absolute;
  top: 0;
  width: toRem(1920);
  height: toRem(1440);
  opacity: 0.5;
}

.background-color {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(52.19% 100% at 50% 0%, #0000 0%, #fff 95.31%);
}
