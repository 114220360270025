@import '../../../../shared/shared';

.section {
  padding: toRem(52) toRem(16) toRem(68);

  @media screen and (min-width: $lg) {
    padding: toRem(68) toRem(64) toRem(84);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(64) toRem(100) toRem(120);
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 700;
  color: #2f345a;
  margin: toRem(32) 0 toRem(48);

  .mint {
    color: #43c2cd;
  }

  @media screen and (min-width: $lg) {
    font-size: toRem(48);
    padding: toRem(32) 0 toRem(48);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(64);
    padding: toRem(32) 0 toRem(64);
  }
}
