@import '../../shared/shared';

.btn {
  font-family: var(--font-family);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 700;
  line-height: toRem(24); /* 150% */
  color: #2f345a;
  border-radius: toRem(8);
  border: toRem(1) solid rgba(35, 140, 131, 0.2);
  background: #43c2cd;
  box-shadow: toRem(0) toRem(10) toRem(15) toRem(0) rgba(0, 0, 0, 0.08);
  padding: toRem(16) toRem(24);

  @media screen and (min-width: $lg) {
    padding: toRem(16) toRem(32);
  }
}
