@import '../../shared/shared';

.service-btn {
  display: inline-block;
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 500;
  color: #43c2cd;
  text-align: center;
  border-radius: toRem(8);
  background: rgba(67, 194, 205, 0.15);
  padding: toRem(8) toRem(16);

  @media screen and (min-width: $lg) {
    font-size: toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(24);
  }
}
