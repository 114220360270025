@import '../../shared/shared';

.header {
  width: 100%;
  position: absolute;
  z-index: 1;
  @include between-centered();
  background: linear-gradient(
    180deg,
    #fff -22.12%,
    rgba(255, 255, 255, 0) 118.27%
  );
  padding: toRem(24) toRem(16);

  @media screen and (min-width: $lg) {
    padding: toRem(24) toRem(64);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(24) toRem(100);
  }
}

.logo {
  @include display-flex();
  width: toRem(104.056);

  @media screen and (min-width: $lg) {
    width: toRem(120);
  }
  @media screen and (min-width: $two-xl) {
    width: toRem(156.084);
  }
}
