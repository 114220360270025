@import '../../shared/shared';

.container {
  @include column-centered();
  background: #222223;
  padding: toRem(24) toRem(16);
  border-radius: toRem(16);
  border: toRem(1) solid rgba(47, 52, 90, 0.08);
  box-shadow: toRem(0) toRem(24) toRem(48) toRem(0) rgba(47, 52, 90, 0.08);
  margin-top: toRem(52);

  @media screen and (min-width: $lg) {
    padding: toRem(48) toRem(8);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(64) toRem(8);
  }
}

.desc {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 700;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) -31.12%,
    #fff 47.48%,
    rgba(255, 255, 255, 0.3) 126.52%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: toRem(24) 0 toRem(16);

  @media screen and (min-width: $lg) {
    font-size: toRem(34);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(36);
  }
}

.just-book {
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 700;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  margin: toRem(16) 0 toRem(24);

  @media screen and (min-width: $lg) {
    margin: toRem(16) 0 toRem(36);
  }
  @media screen and (min-width: $two-xl) {
    margin: toRem(16) 0 toRem(48);
  }
}
