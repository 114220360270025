@import '../../shared/shared';

.item {
  padding: toRem(24) toRem(16);
  border-radius: toRem(16);
  border: toRem(2) solid rgba(47, 52, 90, 0.08);
  background: rgba(47, 52, 90, 0.03);
  box-shadow: toRem(0) toRem(24) toRem(48) toRem(0) rgba(47, 52, 90, 0.08);

  @media screen and (min-width: $lg) {
    padding: toRem(24);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(32);
  }
}

.icon-container {
  width: toRem(48);
  height: toRem(48);

  @media screen and (min-width: $lg) {
    width: toRem(56);
    height: toRem(56);
  }
  @media screen and (min-width: $two-xl) {
    width: toRem(64);
    height: toRem(64);
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 700;
  color: #2f345a;
  margin: toRem(16) 0;

  @media screen and (min-width: $lg) {
    font-size: toRem(36);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(40);
  }
}

.desc {
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 700;
  color: rgba(47, 52, 90, 0.6);
  margin: toRem(16) 0;

  @media screen and (min-width: $lg) {
    font-size: toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(24);
  }
}

.areas {
  @include display-flex();
  list-style: none;
  flex-wrap: wrap;
  gap: toRem(8) toRem(16);
}

.area {
  font-family: var(--font-family);
  font-size: toRem(14);
  font-weight: 700;
  color: #2f345a;
  text-align: center;
  border-radius: toRem(8);
  border: toRem(1) solid #2f345a;
  padding: toRem(8) toRem(16);
}
