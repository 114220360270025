@import '../../shared/shared';

.item {
  padding: toRem(24) toRem(16);
  border-radius: toRem(16);
  border: toRem(2) solid rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: toRem(0) toRem(24) toRem(48) toRem(0) rgba(0, 0, 0, 0.12);

  @media screen and (min-width: $md) {
    width: 100%;
  }
  @media screen and (min-width: $lg) {
    padding: toRem(32);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(32);
  }
}

.icon-container {
  width: toRem(48);
  height: toRem(48);

  @media screen and (min-width: $lg) {
    width: toRem(56);
    height: toRem(56);
  }
  @media screen and (min-width: $two-xl) {
    width: toRem(64);
    height: toRem(64);
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 700;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) -31.12%,
    #fff 47.48%,
    rgba(255, 255, 255, 0.3) 126.52%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
  margin: toRem(16) 0;

  @media screen and (min-width: $md) {
    font-size: toRem(36);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(40);
  }
}

.desc {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-family);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (min-width: $lg) {
    font-size: toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(24);
  }
}
