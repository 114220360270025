@import '../../../../shared/shared';

.section {
  @include column-centered();
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: toRem(206) toRem(16) toRem(48);

  @media screen and (min-width: $lg) {
    padding: toRem(218) toRem(64) toRem(54);
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(232) toRem(100) toRem(64);
  }
}

.content {
  @include column-centered();
  position: relative;
  width: 100%;
  z-index: 1;
}

.subtitle {
  font-family: var(--font-family);
  font-size: toRem(20);
  font-weight: 700;
  color: rgba(47, 52, 90, 0.5);
  text-align: center;
  margin-bottom: toRem(16);

  @media screen and (min-width: $lg) {
    font-size: toRem(24);
    margin-bottom: toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(32);
    margin-bottom: toRem(24);
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(40);
  font-weight: 900;
  color: #2f345a;
  text-align: center;
  margin-bottom: toRem(64);
  max-width: toRem(480);

  @media screen and (min-width: $lg) {
    max-width: none;
    font-size: toRem(56);
    line-height: toRem(64);
    margin-bottom: toRem(80);
    max-width: toRem(640);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(72);
    line-height: toRem(86.4);
    margin-bottom: toRem(96);
    max-width: toRem(980);
  }

  .mint {
    color: #43c2cd;
  }
}

.btn-container {
  @include centered();
}

.claim {
  color: #43c2cd;
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 500;
  padding: toRem(8) toRem(16);
  gap: toRem(8);
  border-radius: toRem(8);
  text-align: center;
  background: rgba(67, 194, 205, 0.15);
  margin: toRem(58) 0 toRem(24);

  @media screen and (min-width: $lg) {
    font-size: toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(24);
  }
}

.blockchain-companies {
  @include centered();
  width: 100%;
  gap: toRem(64);
  list-style: none;
  margin-bottom: toRem(106);

  @media screen and (min-width: $lg) {
    gap: toRem(156);
    margin-bottom: toRem(124);
  }
  @media screen and (min-width: $two-xl) {
    gap: toRem(218);
    margin-bottom: toRem(141);
  }
}

.blockchain-company {
  @include column-centered();

  .image-container {
    width: toRem(64);
    height: toRem(64);

    @media screen and (min-width: $lg) {
      width: toRem(80);
      height: toRem(80);
    }
    @media screen and (min-width: $two-xl) {
      width: toRem(96);
      height: toRem(96);
    }
  }

  title {
    display: block;
    font-family: var(--font-family);
    font-size: toRem(16);
    font-weight: 700;
    color: #2f345a;
    text-align: center;
    opacity: 0.7;
    margin-top: toRem(12);
  }
}

.goal {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 700;
  color: #2f345a;
  text-align: center;
  border-radius: toRem(16);
  border: toRem(1) solid rgba(47, 52, 90, 0.08);
  background: rgba(47, 52, 90, 0.03);
  box-shadow: toRem(0) toRem(24) toRem(48) toRem(0) rgba(47, 52, 90, 0.08);
  padding: toRem(32) toRem(16);

  .mint {
    color: #43c2cd;
  }

  @media screen and (min-width: $lg) {
    font-size: toRem(36);
    padding: toRem(32) toRem(20);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(48);
    padding: toRem(32);
  }
}
